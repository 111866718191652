<template>
  <div class="ai_hotdoc">
    <AiHead />
    <div class="doc_list">
      <div class="content">
        <ModuleTitle :config="titleConfig" />
        <article-list :honDocuList="list" />
        <LoadMore :finished="finished" @load="load" />
      </div>
    </div>
  </div>
</template>

<script>
import AiHead from '@c/aiHead/index.vue'
import ModuleTitle from '../aiModule/components/moduleTitle.vue'
import LoadMore from '@c/loadMore/index.vue'
import { search } from '@/api/article.js'
export default {
  props: {},
  components: {
    AiHead,
    ModuleTitle,
    LoadMore
  },
  data () {
    return {
      finished: false,
      list: [],
      titleConfig: {
        title: 'AI热门文献',
        describe: '数据技术驱动、赋能医生成长'
      },
      pageConfig: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0
    }
  },
  methods: {
    load () {
      this.pageConfig.pageNum++
      this.getList()
    },
    async getList () {
      const res = await search({
        ...this.pageConfig,
        category: 3,
        keywords: 'ChatGPT'
      })
      const data = res.data
      this.list = [...this.list, ...data]
      this.finished = this.list[0].totalHits <= this.list.length
    }
  },
  created () {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.ai_module {
  min-height: calc(100vh - 284px);
  background-color: #f5f7fb;
}
.content {
  width: 1200px;
  margin: 0 auto;
}
/deep/.list:first-child {
  margin-top: 0;
}
</style>
