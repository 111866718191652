<template>
  <div class="ai_head" ref="aiHead">
    <div class="content">
      <div class="head_img_content">
        <img class="ai_slogan" src="@/assets/img/aiModule/ai_slogan.png" alt="">
        <img src="@/assets/img/aiModule/ai_bg_icon.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data () {
    return {
      rollHeight: 0
    }
  },
  methods: {
    setNavBgStyle (type) {
      const navBox = document.getElementById('nav_box')
      if (type) {
        navBox.classList.add('isBg')
      } else {
        navBox.classList.remove('isBg')
      }
    },
    handleScroll () {
      const scrollTop = document.documentElement.scrollTop
      if (scrollTop > this.rollHeight) {
        this.setNavBgStyle(true)
      } else {
        this.setNavBgStyle()
      }
    },
    setNavStyle (type = false) {
      if (this.$route.path === '/') return
      const navBox = document.getElementById('nav_box')
      if (type) {
        navBox.classList.add('isBg')
        navBox.classList.remove('home_fixed')
      } else {
        navBox.classList.remove('isBg')
        navBox.classList.add('home_fixed')
      }
    },
    setRollHeight () {
      const box = this.$refs.aiHead.getBoundingClientRect()
      if (!box) return
      this.rollHeight = box.height - 52
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.handleScroll)
    this.setNavStyle(true)
  },
  mounted () {
    this.setNavStyle()
    this.setRollHeight()
    document.addEventListener('scroll', this.handleScroll)
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.ai_head {
  height: calc(100vh - 361px);
  min-height: 330px;
  width: 100%;
  background: url('../../assets/img/aiModule/ai_bg.jpg') no-repeat;
  background-size: cover;
  padding-top: 1px;
}
.content {
  display: flex;
  align-items: center;
  width: 1200px;
  height: 100%;
  padding-top: 52px;
  margin: 0 auto;
  .head_img_content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    justify-content: center;
    margin: auto 0;
    img {
      width: 100%;
    }
    .ai_slogan {
      padding: 0 100px 0 50px;
    }
  }
}
</style>
