<template>
  <div class="title_box ">
    <h2 class="tac">{{config.title}}</h2>
    <div class="describe">
      <div class="left"></div>
      <div class="describe_content">{{config.describe}}</div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {

    }
  },
  methods: {

  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.title_box {
  padding: 40px 0;
  h2 {
    font-size: 24px;
    font-weight: 700;
  }
  .describe {
    display: grid;
    grid-template-columns: 52px auto 52px;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    color: #979797;
  }
  .left,
  .right {
    display: grid;
    align-items: center;
    width: 100%;
    &::before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #b6cbff;
      margin-bottom: 1px;
    }
    &::after {
      content: '';
      width: 20px;
      height: 1px;
      background-color: #ffaa86;
    }
  }
  .left {
    transform: rotatey(180deg);
  }
}
</style>
